.App {
  text-align: center;
  display: flex;
  flex-flow: column;
}

.frame{
  flex-grow: 1;
  background: #003300;
  height: 84vh;
}




